html, body #root {
  height: 100vh;
  width: 100vw;
  z-index: -1;
  scrollbar-color: #1cf000 black;
  
}

.App {
  text-align: center;
}



::-webkit-scrollbar{
  background-color: transparent;
}
::-moz-scrollbar-thumb{

  cursor: pointer;
  background: linear-gradient( to right, #00a000, #0066a0);
  border-radius: 15px;
}
::-webkit-scrollbar-thumb{

  cursor: pointer;
  background: linear-gradient( to right, #00a000, #0066a0);
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover{
  background: linear-gradient( to right, #10f000, #0096c0);
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:active{
  background: linear-gradient( to right, #10f000, #0096c0);
  border-radius: 15px;
}
::-webkit-scrollbar-track{
  background-color: transparent;

}
::-webkit-scrollbar-track-piece{
 
}
::-webkit-scrollbar-corner{

}

@font-face {
  font-family: "Progress";
  src: local("Progress"),
   url("./fonts/Progress.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Progress";
  src: local("Stencil Cargo Army"),
   url("./fonts/Stencil Cargo Army.ttf") format("truetype");
  font-weight: bold;
}
@font-face{
  src:"https://use.typekit.net/fgz0njr.css";
  font-family: "lores-9-wide", sans-serif;
  font-weight: 700;
  font-style: normal;
}

body{
  background-color: #000;
  overflow-y: auto;
  overflow-x: hidden;
}

.App-link {
  color: #61dafb;
}



.thumbnail {
  height: 200px;
  width: 200px;
}



.cardImageProperties{
  height: 100%!important;
  width: 100%!important;
}

.cardCommonBorder{
  box-shadow: 0 0 20px 0 grey;
}

.toggleButton{
  opacity: 0.5;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.toggleButton:hover{
  opacity: 0.9;
}




.buttonCancel{
  background-color: #8f0005!important;
  color: white!important;
  border: 1px solid #f3e2a2!important;
  border-radius: 5px!important;

}
.buttonCancel:hover{
  background-color: purple!important;
  color: white!important;
  border: 1px solid pink!important;
  border-radius: 5px!important;
  scale: 105%!important;
  transition: 0.5s!important;
}

.buttonCancel:disabled{
  filter: grayscale(100%);
  scale: 90%;
}

.videoStyles{
  margin-top: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100vw!important;
  min-height: 100%;
  width: 100vw!important;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.socialIcon{
  opacity: 0.7;  
}

.socialIcon:hover{
  opacity: 1;
}

.footerStyles{
  display: flex;
  justify-content: center;
  color:black!important;
  width: 100%!important;
  background-color: #121212;
  z-index:100!important;
  height:150px!important;
  border-radius:0px!important;
  border-bottom: 1px solid var(--menu-bar-bottom-border)!important;
  filter: drop-shadow(0 0 10px #000);
}

.flexButtonContainer{
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.flexButtonSeparator{
  width: 100%;
}

.viewSizing{
  position:relative!important;
  top:0px!important;

  align-content:center!important;
  width:100%!important;
  height:100%!important;

  z-index:220!important;
  
}

.homeImageSizing{ 
  position:relative!important;
  top:0px!important;
  overflow-x:hidden!important;
  align-content:center!important;
  width:100%!important;
  height:100%!important;
  max-height:1080px!important;
  max-width:2560px!important;
  min-height:1080px!important;
  min-width:2560px!important;
  z-index:0!important;
}

.st0{
  fill:#0a2900;
  stroke:#b3b645;
  stroke-width:5;
  stroke-miterlimit:10;
}
.st1{
  fill:none;
  stroke:#d2d825;
  stroke-width:5;
  stroke-linecap:square;
  stroke-miterlimit:10;
}
.st2{
  fill:#D6EC00;
}
.st3{
  font-family:'TrajanPro3-Semibold';
}
.st4{
  font-size:48px;

}
.st5{
  fill:url(#SVGID_1_);
  stroke:#b3b645;
  stroke-width:5;
  stroke-linecap:square;
  stroke-miterlimit:10;
}
.st6{
  fill:#0a2900;
}
.svgButtonFont{
  font-family: trajan-pro-3, serif;
  font-weight: 700;
  font-style: normal;
}


.deckSelectMenuItems{
  color:#d2d825!important;
}
.deckSelectMenuItems:hover{
  background-color: #d2d825!important;
  color:#0a2900!important;
}

.newdc{
    text-align: center; 
    color: white;
    font-size: 32pt; 

    background: /* gradient can be an image */
    linear-gradient(
      to left, 
      rgba(255,255,255,0) 10%,
      rgba(255,255,255,1) 50%,
      rgba(255,255,255,0) 90%
    )
    left 
    bottom
    no-repeat; 
  background-size:100% 1px ;/* if linear-gradient, we need to resize it */
}
.cardsInDeckTopDivider{
  border-top:8px ridge #0a0;

}

.sectionHeaders {
  width: 100%;
  color: white;
  font-family: trajan-pro-3, serif;
  text-align: center;
}

@media (min-width:300px){
  .sectionHeaders{
    font-size: 1.5rem;
  }
}

@media (min-width:550px){
  .sectionHeaders{
    font-size: 2rem;
  }
}

@media (min-width:800px){
  .sectionHeaders{
    font-size: 3rem;
  }
}
@media (min-width:1100px){
  .sectionHeaders{
    font-size: 3.5rem;
  }
}

.updateCard{

  padding: 0px;
  background-color: rgba(0,0,0,.5);
  border-radius: 10px;
  border-color: white;
  border: 1px solid;
  height: 300px;
  display: flex;

  flex-direction: column;

}

.topSectionText{

  margin-top: -150px;
  color: white;
  font-size: 12pt;
  background-color: rgba(0,0,0, .4);
  padding: 10px;
  border-radius: 10px;
}

.interactiveInfographic{
  filter: drop-shadow(0 0 10px #000);
  max-height:350px;
  transform: scale(0.8);
}
.interactiveInfographic:hover{
  filter: drop-shadow(0 0 10px red);
  transform: scale(0.9);
  cursor: pointer;

}

.underlineCommon{
  background: /* gradient can be an image */
  linear-gradient(
    to left, 
    rgba(255,255,255,0) 10%,
    rgba(130,130,130,1) 50%,
    rgba(255,255,255,0) 90%
  )
  left 
  bottom
  no-repeat; 
background-size:100% 1px ;/* if linear-gradient, we need to resize it */
}

.underlineUncommon{
  background: /* gradient can be an image */
  linear-gradient(
    to left, 
    rgba(255,255,255,0) 10%,
    rgba(0,255,42,1) 50%,
    rgba(255,255,255,0) 90%
  )
  left 
  bottom
  no-repeat; 
background-size:100% 1px ;/* if linear-gradient, we need to resize it */
}

.underlineRare{
  background: /* gradient can be an image */
  linear-gradient(
    to left, 
    rgba(255,255,255,0) 10%,
    rgba(0,119,255,1) 50%,
    rgba(255,255,255,0) 90%
  )
  left 
  bottom
  no-repeat; 
background-size:100% 1px ;/* if linear-gradient, we need to resize it */
}


.underlineEpic{
  background: /* gradient can be an image */
  linear-gradient(
    to left, 
    rgba(255,255,255,0) 10%,
    rgba(132,0,255,1) 50%,
    rgba(255,255,255,0) 90%
  )
  left 
  bottom
  no-repeat; 
background-size:100% 1px ;/* if linear-gradient, we need to resize it */
}


.underlineLegendary{
  background: /* gradient can be an image */
  linear-gradient(
    to left, 
    rgba(255,255,255,0) 10%,
    rgba(255,196,0,1) 50%,
    rgba(255,255,255,0) 90%
  )
  left 
  bottom
  no-repeat; 
background-size:100% 1px ;/* if linear-gradient, we need to resize it */
}


.underlineFeelingLucky{
  background: /* gradient can be an image */
  linear-gradient(
    to left, 
    rgba(255,255,255,0) 10%,
    rgba(251,72,196,1) 50%,
    rgba(255,255,255,0) 90%
  )
  left 
  bottom
  no-repeat; 
  background-size:100% 1px ;/* if linear-gradient, we need to resize it */
}

.fontify{
  font-size: 32px;
  font-weight: bold;
  font-family:  progress, serif;
}

@media  (min-width: 600px) {
  .fontify{
    font-size: 48px;
    font-weight: bold;
    font-family:  progress, serif;
  }
}




.headline {
  font-family: "Progress";
  font-weight: 100;
  font-size: 8vw;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#ff0000, #ffff00);
  background-clip: text;
  -webkit-background-clip: text;
}

.smallHeadline {
  font-family: "lores-9-wide";
  font-weight: 100;
  font-size: 28pt;
  width: 100%;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#60af60 0%, #0cbcdf 100%);

  background-clip: text;
  -webkit-background-clip: text;
  vertical-align: top;

  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction:row;

}


.inset {
  position: absolute;
  inset: 0;
}
.white {
  color: white;
}
.gray {
  color: #446;
}

.full {
  height: 140vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient {
  background: linear-gradient(rgba(0, 0, 0, 0.75) 50%, black);
}

.abs{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100vw;
}


.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0;
}

.timeline-container::after {
  background-color: #f1ab07;
  content: '';
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #232323;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 400px;
  max-width: 70%;
  text-align: right;
  left:-25px;
}

.timeline-item-content::after {
  content: ' ';
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
  left:25px;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  border-radius:5px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}

.timeline-item-content time {
  color: #ccf;
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 250px;
}

.timeline-item-content a {
color: #333;
text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: ' ►';
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #f1ab07;
  border: 3px solid #f1ab07;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 13px);
  right: -38px;
  width: 20px;
  height: 20px;
  z-index: 150;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  top: calc(50% - 13px);
  left: -38px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
      max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
      padding: 15px 10px;
      text-align: center;
      align-items: center;
  }

  .timeline-item-content .tag {
      width: calc(100% - 19px);
      text-align: center;
  }

  .timeline-item-content time {
      margin-top: 20px;
  }

  .timeline-item-content a {
      text-decoration: underline;
  }

  .timeline-item-content a::after {
      display: none;
  }
}


.coinListingLinks{
  height: auto;
  width: auto;
  max-height: 150px;
  z-index: 800;
  margin: 5px;
  opacity: 1;
  transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
  filter: drop-shadow(0 0 1px white);
  cursor: pointer;
}

@media  (min-width: 400px) {

  .coinListingLinks{
    height: auto;
    width: auto;
    max-height: 100px;
    z-index: 800;
    margin: 5px;
    opacity: .8;
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}
@media  (min-width: 600px) {

  .coinListingLinks{
    height: auto;
    width: auto;
    max-height: 100px;
    z-index: 800;
    margin: 5px;
    opacity: .8;
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}
@media  (min-width: 1100px) {

  .coinListingLinks{
    height: auto;
    width: auto;
    max-height: 150px;
    z-index: 800;
    margin: 5px;
    opacity: .8;
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}

.coinListingLinks:hover{
  scale:1.2;
  opacity: 1;
  filter: drop-shadow(0px 0px 5px rgba(255,255,255,1));
}

.cardAreaBlue{
  background-color: rgba(0, 30, 160, 0)!important;
}

.cardAreaGreen{
  background-color: rgba(0, 180, 98, 0)!important;
}

.cardAreaGold{
  background-color: rgba(255, 208, 0, 0)!important;
}


.cardHoverGreen{
  opacity: .8;
  scale:.9;
  transition: all .2s linear;;
  color: white;
  background-color: rgba(0,0,0,.5);

}
.cardHoverGreen:hover{
  scale:1;
  color: rgb(0, 255, 98)!important;
  opacity: 1;
  
}

.cardHoverBlue{
  color: white;
  opacity: .8;
  scale:.9;
  background-color: rgba(0,0,0,.5);
  transition: all .5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.cardHoverBlue:hover{
  scale:1;
  color: rgb(89, 155, 255)!important;
  opacity: 1;
}

.cardHoverGold{
  opacity: .8;
  scale:.9;
  color: white;
  background-color: rgba(0,0,0,.5);
  transition: all .5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.cardHoverGold:hover{
  scale:1;
  color: rgba(255, 208, 0, 1)!important;

  opacity: 1;
  
}